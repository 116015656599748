import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useI18n } from 'services/i18n';
import { Background, Container, Row, Col } from 'styles/grid';
import { BiggerText, SecondaryTitle, Text } from 'components/texts';
import NonStretchedImage from 'components/non-stretched-image';
import Links from 'components/links';
import {
  StyledCol,
  ArticleCol,
  BenefitsContainer,
  BenefitsCol,
  LeftTitle,
  LeftText,
  DescriptionContainer,
  CaseStudyLink,
  DescriptionItem,
} from 'components/case-study';

const CaseStudyShilton = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      intro: file(relativePath: { eq: "case-study-shilton/intro.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      case_study_en: file(relativePath: { eq: "case-study-shilton/en/caseStudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      case_study_fr: file(relativePath: { eq: "case-study-shilton/fr/caseStudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <section>
        <SEO
          title={t('seo.title.case-study-shilton')}
          lang={language}
          description={t('seo.description.case-study-shilton')}
        />
        <Container>
          <Row>
            <Col sm={12} md={8}>
              <BiggerText color="fitle" weight="bold">
                {t('case-study-shilton.introductory-bigger-text')}
              </BiggerText>
              <SecondaryTitle as="h1" desktopAlign="left">
                {t('case-study-shilton.title')}
              </SecondaryTitle>
              <Text>{t('case-study-shilton.title-caption')}</Text>
            </Col>
            <StyledCol sm={12} md={4} align="center">
              <NonStretchedImage
                fluid={data.intro.childImageSharp.fluid}
                alt={t(`seo.alt.caseStudyShilton.intro`)}
              />
            </StyledCol>
          </Row>
        </Container>
      </section>
      <Background>
        <section>
          <BenefitsContainer>
            <Row justify="space-around" align="stretch">
              <BenefitsCol sm={10} md={3}>
                <Text desktopAlign="center" style={{ marginBottom: 10 }}>
                  {t('case-study-shilton.cart-abandonment-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-shilton.cart-abandonment-value')}
                </BiggerText>
              </BenefitsCol>
              <BenefitsCol sm={10} md={3}>
                <Text desktopAlign="center" style={{ marginBottom: 10 }}>
                  {t('case-study-shilton.conversion-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-shilton.conversion-value')}
                </BiggerText>
              </BenefitsCol>
              <BenefitsCol sm={10} md={3}>
                <Text desktopAlign="center" style={{ marginBottom: 10 }}>
                  {t('case-study-shilton.average-cart-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-shilton.average-cart-value')}
                </BiggerText>
              </BenefitsCol>
            </Row>
          </BenefitsContainer>
        </section>
      </Background>
      <section>
        <Container>
          <Row>
            <DescriptionContainer>
              <DescriptionItem label={t('case-study-shilton.used-product-key')}>
                <CaseStudyLink to="/plugin">
                  {t('case-study-shilton.used-product-value')}
                </CaseStudyLink>
              </DescriptionItem>
              <DescriptionItem
                label={t('case-study-shilton.headquarters-key')}
                value={t('case-study-shilton.headquarters-value')}
              />
              <DescriptionItem
                label={t('case-study-shilton.business-type-key')}
                value={t('case-study-shilton.business-type-value')}
              />
            </DescriptionContainer>
            <Col md={1}></Col>
            <ArticleCol sm={12} md={8}>
              <SecondaryTitle desktopAlign="left" align="left">
                {t('case-study-shilton.article-title')}
              </SecondaryTitle>
              {/* block one */}
              <LeftText>
                <Text as="span" weight="bold">
                  {t('case-study-shilton.article-block-one.paragraph-one-bold')}
                </Text>
                {t('case-study-shilton.article-block-one.paragraph-one')}
              </LeftText>
              <LeftText style={{ marginBottom: 40 }}>
                {t('case-study-shilton.article-block-one.paragraph-two')}
                <Text as="span" weight="bold">
                  {t('case-study-shilton.article-block-one.paragraph-two-bold')}
                </Text>
                {t('case-study-shilton.article-block-one.paragraph-two-part-two')}
              </LeftText>
              {/* block two */}
              <LeftTitle>{t('case-study-shilton.article-block-two.title')}</LeftTitle>
              <LeftText>
                {t('case-study-shilton.article-block-two.paragraph-one')}{' '}
                <Text as="span" weight="bold">
                  {t('case-study-shilton.article-block-two.paragraph-one-bold')}
                </Text>
              </LeftText>
              <LeftText>
                {t('case-study-shilton.article-block-two.paragraph-two')}
                <Text as="span" weight="bold">
                  {t('case-study-shilton.article-block-two.paragraph-two-bold')}
                </Text>
              </LeftText>
              <LeftText style={{ marginBottom: 40 }}>
                {t('case-study-shilton.article-block-two.paragraph-three')}
              </LeftText>
              {/* block three */}
              <LeftTitle>{t('case-study-shilton.article-block-three.title')}</LeftTitle>
              <LeftText>{t('case-study-shilton.article-block-three.paragraph-one')}</LeftText>
              <LeftText>
                {t('case-study-shilton.article-block-three.paragraph-two')}
                <Text as="span" weight="bold">
                  {t('case-study-shilton.article-block-three.paragraph-two-bold')}
                </Text>
              </LeftText>
              <LeftText>{t('case-study-shilton.article-block-three.paragraph-three')}</LeftText>
              <div style={{ marginTop: 80 }}>
                <NonStretchedImage
                  fluid={data['case_study_' + language].childImageSharp.fluid}
                  alt={t(`seo.alt.caseStudyShilton.caseStudy`)}
                />
              </div>
            </ArticleCol>
          </Row>
        </Container>
      </section>
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default CaseStudyShilton;
